import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import PageHeader from 'components/PageHeader'
import Navigation from 'containers/Navigation/Navigation'
import { Sticky } from 'react-sticky'

const ReportHeader = ({ name, sticky, children, reverseNav }) => {
  if (sticky) {
    return (
      <Sticky topOffset={-50}>
        {({ isSticky, style }) => (
          <div
            className={classnames(
              'report-header',
              isSticky && 'report-header--sticky'
            )}
            style={style}
          >
            <PageHeader
              title={<h1>{name}</h1>}
              className='report-header__title'
            />

            <Navigation reverse={reverseNav} />
            {children}
          </div>
        )}
      </Sticky>
    )
  } else {
    return (
      <div className='report-header'>
        <PageHeader title={<h1>{name}</h1>} className='report-header__title' />

        <Navigation reverse={reverseNav} />
        {children}
      </div>
    )
  }
}

ReportHeader.propTypes = {
  name: PropTypes.string.isRequired,
  sticky: PropTypes.bool,
  children: PropTypes.any,
  reverseNav: PropTypes.bool
}

ReportHeader.defaultPropts = {
  reverseNav: false
}

export default ReportHeader
