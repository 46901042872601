import React from 'react'
import PropTypes from 'prop-types'
import DateNavigation from 'components/DateNavigation'
import { DAY, MONTH } from 'containers/Navigation/const'

class NavigationMonthlyRange extends React.Component {
  setLength = (date) => {
    const fixedDate = date.startOf('month')
    const length = this.props.date.diff(fixedDate, 'months') + 1

    if (length > 0) {
      this.props.setNavigation({
        date: fixedDate.clone().add(length - 1, 'months'),
        length
      })
    } else {
      this.rescueNegativeLength(date)
    }
  }

  setDate = (date) => {
    const fixedDate = date.startOf('month')
    const length = this.props.length + fixedDate.diff(this.props.date, 'months')

    if (length > 0) {
      this.props.setNavigation({ date: fixedDate, length })
    } else {
      this.rescueNegativeLength(date)
    }
  }

  rescueNegativeLength = (date) => {
    this.props.setNavigation({
      date,
      length: 1
    })
  }

  render () {
    const { date, length, scope, children } = this.props
    const to = date.clone()
    const from = to.clone().subtract(length - 1, 'months')

    return (
      <React.Fragment>
        {children}
        <div style={{ display: 'flex' }}>
          <div className='report-header__navigation-item'>
            <DateNavigation
              date={from}
              scope={scope}
              setDate={this.setLength}
              maxDate={to.clone().endOf('month')}
              minDate={to
                .clone()
                .subtract(3, 'years')
                .endOf('month')}
            />
          </div>
          <div className='report-header__navigation-item'>
            <DateNavigation
              date={to}
              scope={scope}
              setDate={this.setDate}
              minDate={from}
              popperPlacement='top-end'
              popperModifiers={{
                offset: {
                  enabled: true,
                  offset: '40px, 0'
                }
              }}
            />
          </div>
        </div>
      </React.Fragment>
    )
  }
}

NavigationMonthlyRange.propTypes = {
  date: PropTypes.object.isRequired,
  length: PropTypes.number.isRequired,
  setNavigation: PropTypes.func.isRequired,
  scope: PropTypes.oneOf([DAY, MONTH]).isRequired,
  children: PropTypes.any
}

export default NavigationMonthlyRange
