import { call, delay } from 'redux-saga/effects'

const TOTAL_RETRIES = 5

function retryableSagaFactory (child) {
  return function * () {
    for (let i = 0; i < TOTAL_RETRIES; ++i) {
      try {
        yield call(child)
      } catch (e) {
        console.error(e)
        yield delay(500)
      }
    }
  }
}

function keepAlive (root, middleware) {
  let saga = middleware.run(retryableSagaFactory(root))

  return saga
}

export default keepAlive
