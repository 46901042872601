import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import Icon from 'components/Icon'
import { COLORS } from 'lib/const'
import { extractDataAttributes } from '../lib/helpers'

export default class Button extends React.Component {
  static defaultProps = {
    color: 'default',
    size: 'default',
    block: false,
    isLoading: false,
    onClick: () => {},
    tag: 'button',
    disabled: false
  }

  static propTypes = {
    color: PropTypes.oneOf(COLORS),
    size: PropTypes.oneOf(['default', 'small', 'large']),
    block: PropTypes.bool,
    isLoading: PropTypes.bool,
    onClick: PropTypes.func,
    children: PropTypes.any,
    tag: PropTypes.string,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    style: PropTypes.object
  }

  click = (e) => {
    e.target.blur()
    this.ref.blur()
    if (!this.props.isLoading) {
      this.props.onClick(e)
    }
  }

  getClassNames = () => {
    const { color, size, block, isLoading, className } = this.props

    return classnames(
      'btn',
      color !== 'default' ? 'btn--' + color : null,
      size !== 'default' ? 'btn--' + size : null,
      block ? 'btn--block' : null,
      isLoading ? 'btn--loading' : null,
      className
    )
  }

  setRef = node => {
    this.ref = node
  }

  render () {
    const { isLoading, children, tag: Tag, disabled, style, ...rest } = this.props
    const dataAttrs = extractDataAttributes(rest)

    return (
      <Tag className={this.getClassNames()} onClick={this.click} ref={this.setRef} disabled={disabled} style={style} {...dataAttrs}>
        {isLoading && <div className='btn__loader'><Icon name='gear' spin size='lg' /></div>}
        {children}
      </Tag>
    )
  }
}
