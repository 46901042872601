import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import ToggleButton from 'components/ToggleButton'
import { FormattedMessage } from 'react-intl'

class ScopeNavigation extends Component {
  static propTypes = {
    monthly: PropTypes.boolean,
    scope: PropTypes.oneOf(['day', 'week']).isRequired,
    setScope: PropTypes.func.isRequired
  }

  setDayScope = e => this.props.setScope('day')
  setWeekScope = e => this.props.setScope('week')
  setMonthScope = e => this.props.setScope('month')

  render () {
    const { scope, monthly } = this.props

    return (
      <Fragment>
        <ToggleButton color='default' isToggled={scope === 'day'} onToggleOn={this.setDayScope}>
          <FormattedMessage id='components.navigation.daily' defaultMessage='Daily' />
        </ToggleButton>
        <ToggleButton color='default' isToggled={scope === 'week'} onToggleOn={this.setWeekScope}>
          <FormattedMessage id='components.navigation.weekly' defaultMessage='Weekly' />
        </ToggleButton>
        { monthly && (
          <ToggleButton color='default' isToggled={scope === 'month'} onToggleOn={this.setMonthScope}>
            <FormattedMessage id='components.navigation.monthly' defaultMessage='Monthly' />
          </ToggleButton>
        ) }
      </Fragment>
    )
  }
}

ScopeNavigation.propTypes = {
  scope: PropTypes.oneOf(['day', 'week', 'month']).isRequired,
  setScope: PropTypes.func.isRequired
}

export default ScopeNavigation
