import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  selectNavigationDate,
  selectNavigationLength,
  selectNavigationScope,
  selectNavigationShift,
  selectNavigationType,
  selectNavigationLocation,
  selectNavigationLocations
} from './selectors'
import {
  setNavigation,
  setNavigationDate,
  setNavigationLocation,
  setNavigationLocations,
  setNavigationLength,
  setNavigationScope,
  setNavigationShift
} from './actions'
import {
  Scopes,
  Shifts,
  Types,
  TYPE_WEEK,
  TYPE_WEEK_DAY,
  TYPE_WEEK_DAY_MONTH,
  TYPE_DAY_SHIFT,
  TYPE_WEEK_DAY_SHIFT,
  TYPE_MONTHS_TO_DATE,
  TYPE_RANGE,
  TYPE_MONTHLY_RANGE,
  TYPE_YEAR_TO_DATE,
  TYPE_YEAR_TO_DATE_WITH_LOCATION
} from './const'
import NavigationDay from './NavigationDay'
import NavigationWeekDay from './NavigationWeekDay'
import NavigationYearToDate from './NavigationYearToDate'
import NavigationYearToDateWithLocation from './NavigationYearToDateWithLocation'
import NavigationMonthsToDate from './NavigationMonthsToDate'
import NavigationRange from './NavigationRange'
import NavigationMonthlyRange from './NavigationMonthlyRange'
import classnames from 'classnames'

const Navigation = ({ type, reverse, ...props }) => {
  return (
    <div
      className={classnames('report-header__navigation', {
        'report-header__navigation--reverse': reverse
      })}
    >
      {[TYPE_WEEK_DAY, TYPE_WEEK_DAY_SHIFT, TYPE_WEEK, TYPE_WEEK_DAY_MONTH].includes(type) && (
        <NavigationWeekDay type={type} {...props} />
      )}
      {type === TYPE_YEAR_TO_DATE && <NavigationYearToDate {...props} />}
      {type === TYPE_YEAR_TO_DATE_WITH_LOCATION && (
        <NavigationYearToDateWithLocation {...props} />
      )}
      {type === TYPE_DAY_SHIFT && <NavigationDay type={type} {...props} /> }
      {type === TYPE_MONTHS_TO_DATE && <NavigationMonthsToDate {...props} />}
      {type === TYPE_RANGE && <NavigationRange {...props} />}
      {type === TYPE_MONTHLY_RANGE && <NavigationMonthlyRange {...props} />}
    </div>
  )
}

Navigation.defaultProps = {
  type: 'weekDayShift',
  reverse: false
}

Navigation.propTypes = {
  setNavigation: PropTypes.func.isRequired,
  date: PropTypes.object.isRequired,
  setDate: PropTypes.func.isRequired,
  length: PropTypes.number.isRequired,
  setLength: PropTypes.func.isRequired,
  scope: PropTypes.oneOf(Scopes).isRequired,
  setScope: PropTypes.func.isRequired,
  shift: PropTypes.oneOf(Shifts),
  setShift: PropTypes.func,
  type: PropTypes.oneOf(Types),
  reverse: PropTypes.bool
}

const mapStateToProps = (state) => ({
  scope: selectNavigationScope(state),
  date: selectNavigationDate(state),
  length: selectNavigationLength(state),
  shift: selectNavigationShift(state),
  type: selectNavigationType(state),
  location: selectNavigationLocation(state),
  locations: selectNavigationLocations(state)
})

const mapDispatchToProps = {
  setNavigation: setNavigation,
  setScope: setNavigationScope,
  setDate: setNavigationDate,
  setLength: setNavigationLength,
  setShift: setNavigationShift,
  setLocation: setNavigationLocation,
  setLocations: setNavigationLocations
}

export default connect(mapStateToProps, mapDispatchToProps)(Navigation)
