import axios from 'axios'
import _ from 'lodash'
import { isMoment } from 'moment-timezone'
import { compose } from 'redux'
import camelizeKeys from 'camelcase-keys-deep'
import decamelizeKeys from 'decamelize-keys-deep'

const stringifyMoments = data => {
  return _.mapValues(data, value => {
    if (isMoment(value)) {
      return value.toISOString()
    } else if (_.isArray(value)) {
      return _.values(stringifyMoments(value))
    } else if (_.isObject(value)) {
      return stringifyMoments(value)
    } else {
      return value
    }
  })
}

const parseJSON = data => typeof data === 'string' ? JSON.parse(data) : data

const transformRequest = data => compose(JSON.stringify, decamelizeKeys, stringifyMoments)(data)
const transformSearchParams = data => compose(decamelizeKeys, stringifyMoments)(data)
const transformResponse = compose(camelizeKeys, parseJSON)

let axiosInstance = axios.create({
  headers: {
    'Content-Type': 'application/json'
  },
  transformRequest,
  transformResponse,
  responseType: 'json',
  baseURL: '/api'
})

axiosInstance.interceptors.request.use((config) => {
  const { token, headers, ...rest } = config

  let params = config.params
  if (config.params) {
    params = transformSearchParams(config.params)
  }

  return {
    ...rest,
    params,
    headers: { ...headers, 'Authorization': `Bearer ${token}` }
  }
})

axiosInstance.interceptors.response.use(
  response => response.data,
  error => {
    if (error.status === 422) {
      return error.response.data
    } else {
      return Promise.reject(error)
    }
  }
)

export default axiosInstance
