import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import Button from 'components/Button'
import Icon from 'components/Icon'
import { Shifts } from 'containers/Navigation/const'
import DropdownButtonSimple from 'components/DropdownButtonSimple'

const MonthsMessage = ({ count }) => (
  <FormattedMessage
    id='containers.navigation.months'
    defaultMessage='{count, plural, one {1 month} other {{count} months}}'
    values={{ count }}
  />
)

MonthsMessage.propTypes = {
  count: PropTypes.number.isRequired
}

const NavigationMonthsToDate = ({ length, setLength, date, setDate, shift, setShift }) => (
  <div className='report-header__navigation'>
    <div className='report-header__navigation-item'>
      <DropdownButtonSimple
        color='default'
        style={{ minWidth: 135, textAlign: 'center' }}
        className='btn--flex'
        content={({ close }) => (
          <React.Fragment>
            <Button
              block
              style={{ paddingRight: 35 }}
              onClick={e => {
                setLength(length === 3 ? 6 : 3)
                close()
              }}
            >
              <MonthsMessage count={length === 3 ? 6 : 3} />
            </Button>
          </React.Fragment>
        )}
      >
        <MonthsMessage count={length} />
        <Icon name='caret-down' fw style={{ marginLeft: 3.5 }} />
      </DropdownButtonSimple>
    </div>

    <div className='report-header__navigation-item'>
      <Button color='secondary' onClick={() => { setDate(date.clone().subtract(length, 'month')) }}>
        <Icon name='caret-left' />
        <Icon name='caret-left' />
      </Button>
      <Button color='secondary' onClick={() => { setDate(date.clone().subtract(1, 'month')) }}>
        <Icon name='caret-left' />
      </Button>
      <Button color='secondary' onClick={() => { setDate(date.clone().add(1, 'month')) }}>
        <Icon name='caret-right' />
      </Button>
      <Button color='secondary' onClick={() => { setDate(date.clone().add(length, 'month')) }}>
        <Icon name='caret-right' />
        <Icon name='caret-right' />
      </Button>
    </div>
  </div>
)

NavigationMonthsToDate.propTypes = {
  date: PropTypes.object.isRequired,
  setDate: PropTypes.func.isRequired,
  length: PropTypes.number.isRequired,
  setLength: PropTypes.func.isRequired,
  shift: PropTypes.oneOf(Shifts),
  setShift: PropTypes.func
}

export default NavigationMonthsToDate
