export const extractDataAttributes = props => {
  const result = {}
  Object.entries(props).forEach(([key, value]) => {
    if (key.startsWith('data-')) {
      result[key] = value
    }
  })

  return result
}
