import React from 'react'
import PropTypes from 'prop-types'
import NavigationYearToDate from './NavigationYearToDate'
import { Shifts } from 'containers/Navigation/const'
import NavigationLocations from './NavigationLocations'

const NavigationYearToDateWithLocation = ({
  date,
  setDate,
  shift,
  setShift,
  location,
  locations,
  setLocation
}) => (
  <NavigationYearToDate
    date={date}
    setDate={setDate}
    shift={shift}
    setShift={setShift}
  >
    <NavigationLocations
      location={location}
      locations={locations}
      setLocation={setLocation}
    />
  </NavigationYearToDate>
)

const locationShape = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string
})
NavigationYearToDateWithLocation.propTypes = {
  date: PropTypes.object.isRequired,
  setDate: PropTypes.func.isRequired,
  shift: PropTypes.oneOf(Shifts),
  setShift: PropTypes.func,
  location: locationShape,
  locations: PropTypes.arrayOf(locationShape),
  setLocation: PropTypes.func
}

export default NavigationYearToDateWithLocation
