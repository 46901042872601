import { connect } from 'react-redux'
import { selectLocationIds } from 'reports/scrapPareto/selectors'
import ScrapParetoReportLocationToggle from 'reports/scrapPareto/ScrapParetoReportLocationToggle'
import ProductionReportLocations from 'components/ProductionReportLocations'
import ScrapParetoLocationSelectNone from './ScrapParetoLocationSelectNone'
import ScrapParetoLocationSelectAll from './ScrapParetoLocationSelectAll'

const mapStateToProps = (state) => ({
  ids: selectLocationIds(state),
  Toggle: ScrapParetoReportLocationToggle,
  SelectAll: ScrapParetoLocationSelectAll,
  SelectNone: ScrapParetoLocationSelectNone
})

export default connect(mapStateToProps)(ProductionReportLocations)
