import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const getClassName = ({ name, size, pull, fw, border, spin, rotate, className }) => classnames(
  'fa',
  className,
  'fa-' + name,
  size !== 'default' ? 'fa-' + size : null,
  pull !== 'none' ? 'fa-pull-' + pull : null,
  fw ? 'fa-fw' : null,
  border ? 'fa-border' : null,
  spin ? 'fa-spin' : null,
  rotate ? 'fa-rotate-' + rotate : null
)

const Icon = ({ name, size, pull, fw, border, spin, children, rotate, className, style, ...restProps }) => (
  <i className={getClassName({ name, size, pull, fw, border, spin, rotate, className })} style={style} {...restProps}>
    {children}
  </i>
)

Icon.defaultProps = {
  size: 'default',
  pull: 'none',
  fw: false,
  border: false,
  spin: false
}

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  size: PropTypes.oneOf(['default', 'lg', '2x', '3x', '4x', '5x']),
  pull: PropTypes.oneOf(['none', 'left', 'right']),
  rotate: PropTypes.oneOf([90, 180, 270]),
  fw: PropTypes.bool,
  border: PropTypes.bool,
  spin: PropTypes.bool,
  children: PropTypes.any,
  className: PropTypes.string,
  style: PropTypes.object
}

export default Icon
