import React from 'react'
import PropTypes from 'prop-types'
import DateNavigation from 'components/DateNavigation'
import ScopeNavigation from 'components/ScopeNavigation'
import ShiftNavigation from 'components/ShiftNavigation'
import NavigationLocations from './NavigationLocations'
import {
  DAY,
  WEEK,
  MONTH,
  TYPE_WEEK_DAY,
  TYPE_WEEK_DAY_MONTH,
  TYPE_WEEK_DAY_SHIFT,
  TYPE_WEEK,
  Shifts
} from 'containers/Navigation/const'

const NavigationWeekDay = ({
  date,
  setDate,
  scope,
  setScope,
  shift,
  setShift,
  type,
  location,
  locations,
  setLocation
}) => (
  <div className='report-header__navigation'>
    {type === TYPE_WEEK && (
      <NavigationLocations
        location={location}
        locations={locations}
        setLocation={setLocation}
      />
    )}
    <div className='report-header__navigation-item'>
      <DateNavigation date={date} scope={scope} setDate={setDate} />
    </div>
    {type !== TYPE_WEEK && (
      <React.Fragment>
        {type === TYPE_WEEK_DAY_SHIFT && (
          <div className='report-header__navigation-item'>
            <ShiftNavigation
              shift={shift}
              disabled={scope === 'week'}
              setShift={setShift}
            />
          </div>
        )}
        <div className='report-header__navigation-item'>
          <ScopeNavigation scope={scope} setScope={setScope} monthly={type === TYPE_WEEK_DAY_MONTH} />
        </div>
      </React.Fragment>
    )}
  </div>
)

const locationShape = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string
})
NavigationWeekDay.propTypes = {
  date: PropTypes.object.isRequired,
  setDate: PropTypes.func.isRequired,
  scope: PropTypes.oneOf([DAY, WEEK, MONTH]).isRequired,
  setScope: PropTypes.func.isRequired,
  shift: PropTypes.oneOf(Shifts),
  setShift: PropTypes.func,
  type: PropTypes.oneOf([TYPE_WEEK, TYPE_WEEK_DAY, TYPE_WEEK_DAY_SHIFT, TYPE_WEEK_DAY_MONTH]),
  location: locationShape,
  locations: PropTypes.arrayOf(locationShape),
  setLocation: PropTypes.func
}

export default NavigationWeekDay
