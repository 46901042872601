export const DAY = 'day'
export const MONTH = 'month'
export const WEEK = 'week'

export const TYPE_WEEK = 'TYPE_WEEK'
export const TYPE_WEEK_DAY = 'TYPE_WEEK_DAY'
export const TYPE_WEEK_DAY_MONTH = 'TYPE_WEEK_DAY_MONTH'
export const TYPE_WEEK_DAY_SHIFT = 'TYPE_WEEK_DAY_SHIFT'
export const TYPE_DAY_SHIFT = 'TYPE_DAY_SHIFT'
export const TYPE_MONTHS_TO_DATE = 'TYPE_MONTHS_TO_DATE'
export const TYPE_RANGE = 'TYPE_RANGE'
export const TYPE_MONTHLY_RANGE = 'TYPE_MONTHLY_RANGE'
export const TYPE_YEAR_TO_DATE = 'TYPE_YEAR_TO_DATE'
export const TYPE_YEAR_TO_DATE_WITH_LOCATION = 'TYPE_YEAR_TO_DATE_WITH_LOCATION'

export const Types = [
  TYPE_WEEK,
  TYPE_WEEK_DAY,
  TYPE_WEEK_DAY_MONTH,
  TYPE_WEEK_DAY_SHIFT,
  TYPE_MONTHS_TO_DATE,
  TYPE_RANGE,
  TYPE_MONTHLY_RANGE,
  TYPE_YEAR_TO_DATE,
  TYPE_YEAR_TO_DATE_WITH_LOCATION,
  TYPE_DAY_SHIFT
]
export const Scopes = [DAY, WEEK, MONTH]
export const Shifts = [1, 2, 3]
