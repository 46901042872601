import React from 'react'
import PropTypes from 'prop-types'
import DatePicker from 'react-datepicker'
import ShiftNavigation from 'components/ShiftNavigation'
import CustomCalendarInput from 'components/CustomCalendarInput'
import { Shifts } from './const'
import { DATE_FORMAT } from 'lib/const'

class NavigationRange extends React.Component {
  selectLength = (date) => {
    const length = this.props.date.diff(date, 'days') + 1
    if (length <= 0) {
      this.rescueNegativeLength(date)
    } else {
      this.props.setNavigation({
        date: date.clone().add(length - 1, 'days'),
        length
      })
    }
  }

  selectDate = (date) => {
    const length = this.props.length + date.diff(this.props.date, 'days')
    if (length <= 0) {
      this.rescueNegativeLength(date)
    } else {
      this.props.setNavigation({ date, length })
    }
  }

  rescueNegativeLength = (date) => {
    this.props.setNavigation({
      date,
      length: 1
    })
  }

  render () {
    const { date, length, shift, setShift } = this.props
    const to = date.clone()
    const from = to.clone().subtract(length - 1, 'days')

    return (
      <div className='report-header__navigation'>
        <div className='report-header__navigation-item'>
          <DatePicker
            onSelect={this.selectLength}
            startDate={from}
            endDate={to}
            minDate={to.clone().subtract(3, 'months')}
            maxDate={to}
            selected={from}
            selectsStart
            dateFormat={DATE_FORMAT}
            customInput={<CustomCalendarInput />}
          />
        </div>
        <div className='report-header__navigation-item'>
          <DatePicker
            onSelect={this.selectDate}
            startDate={from}
            endDate={to}
            minDate={from}
            maxDate={from.clone().add(3, 'months')}
            selected={to}
            dateFormat={DATE_FORMAT}
            selectsEnd
            customInput={<CustomCalendarInput />}
          />
        </div>
        <div className='report-header__navigation-item'>
          <ShiftNavigation shift={shift} disabled={length !== 1} setShift={setShift} ts={date.valueOf()} />
        </div>
      </div>
    )
  }
}

NavigationRange.propTypes = {
  date: PropTypes.object.isRequired,
  setNavigation: PropTypes.func.isRequired,
  length: PropTypes.number.isRequired,
  shift: PropTypes.oneOf(Shifts),
  setShift: PropTypes.func
}

export default NavigationRange
