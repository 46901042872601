import React from 'react'
import DropdownButtonSimple from 'components/DropdownButtonSimple'
import Button from 'components/Button'
import Icon from 'components/Icon'
import PropTypes from 'prop-types'

const NavigationLocations = ({ location, locations, setLocation }) => {
  return (
    <div className='report-header__navigation-item'>
      <DropdownButtonSimple
        color='default'
        style={{ minWidth: 135, textAlign: 'center' }}
        className='btn--flex'
        content={({ close }) => (
          <React.Fragment>
            {locations.map((loc, i) => (
              <Button
                key={loc.id}
                block
                disabled={loc.id === location.id}
                style={{ paddingRight: 35 }}
                onClick={e => {
                  setLocation(loc)
                  close()
                }}
              >
                {loc.name}
              </Button>
            ))}
          </React.Fragment>
        )}
      >
        <span>{location.name}</span>
        <Icon name='caret-down' fw style={{ marginLeft: 3.5 }} />
      </DropdownButtonSimple>
    </div>
  )
}

NavigationLocations.propTypes = {
  location: PropTypes.object,
  locations: PropTypes.array,
  setLocation: PropTypes.func
}

export default NavigationLocations
