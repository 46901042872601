import { call, delay } from 'redux-saga/effects'

const timeUntilFullSecond = () => 5000 - (Date.now() % 5000)

// Pings the console every 5 seconds. Disabled in production
function * heartbeat (name) {
  console.log(name + ': saga started')
  try {
    while (true) {
      const toWait = yield call(timeUntilFullSecond)
      yield delay(toWait)
      console.log(name + ': saga running')
    }
  } finally {
    console.log(name + ': saga stopped')
  }
}

export default heartbeat
