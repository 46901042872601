export const allById = (data) => (
  data.reduce(
    (reduction, a) => {
      reduction[a.id] = a
      return reduction
    },
    {}
  )
)

export const customById = (data, value) => (
  data.reduce(
    (reduction, item) => {
      if (typeof value === 'function') {
        reduction[item.id] = value(item)
      } else {
        reduction[item.id] = value
      }
      return reduction
    },
    {}
  )
)

export const allIds = data => data.map(n => n.id)

export const idsByCustom = (data, key) => (
  data.reduce(
    (result, { id, [key]: value }) => {
      result[value] = result[value] || []
      result[value].push(id)
      return result
    },
    {}
  )
)

export const idsByCustomCollection = (data, collectionKey) => (
  data.reduce(
    (result, { id, [collectionKey]: collection }) => {
      collection.forEach(collectionId => {
        result[collectionId] = result[collectionId] || []
        result[collectionId].push(id)
      })
      return result
    },
    {}
  )
)

export const receiveCollection = (state, { payload: collection }) => ({
  ...state,
  allById: allById(collection),
  allIds: allIds(collection)
})
