import moment from 'moment'
// Don't ask me why, because I have no idea. It works, so it stays. @mus
import momenttz from 'moment-timezone'

const { timezone, locale } = window

export default () => {
  moment.locale(locale)
  moment.updateLocale(moment.locale(), { week: { dow: 1, doy: 4 } })
  momenttz.locale(locale)
  momenttz.updateLocale(moment.locale(), { week: { dow: 1, doy: 4 } })
  momenttz.tz.setDefault(timezone)
}
