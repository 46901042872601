import React from 'react'
import PropTypes from 'prop-types'
import DateNavigation from 'components/DateNavigation'
import ShiftNavigation from 'components/ShiftNavigation'
import { DAY, WEEK, TYPE_DAY_SHIFT, Shifts } from 'containers/Navigation/const'

const NavigationDay = ({ date, setDate, scope, setScope, shift, setShift, type }) => (
  <div className='report-header__navigation'>
    <div className='report-header__navigation-item'>
      <DateNavigation date={date} scope={scope} setDate={setDate} />
    </div>
    {
      type === TYPE_DAY_SHIFT &&
      <div className='report-header__navigation-item'>
        <ShiftNavigation shift={shift} setShift={setShift} ts={date.valueOf()} />
      </div>
    }
  </div>
)

NavigationDay.propTypes = {
  date: PropTypes.object.isRequired,
  setDate: PropTypes.func.isRequired,
  scope: PropTypes.oneOf([DAY, WEEK]).isRequired,
  setScope: PropTypes.func.isRequired,
  shift: PropTypes.oneOf(Shifts),
  setShift: PropTypes.func,
  type: PropTypes.oneOf([TYPE_DAY_SHIFT])
}

export default NavigationDay
