import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import Icon from 'components/Icon'

const getClassName = ({ full, transparent }) => classnames(
  'loader',
  full && 'loader--full',
  transparent && 'loader--transparent'
)

const Loader = ({ full, transparent, size, ...rest }) => (
  <div {...rest} className={getClassName({ full, transparent })}>
    <Icon name='cog' spin size={size + 'x'} fw className='loader__spinner' />
  </div>
)

Loader.defaultProps = {
  full: false,
  transparent: false,
  size: 5
}

Loader.propTypes = {
  full: PropTypes.bool,
  transparent: PropTypes.bool,
  size: PropTypes.number
}

export default Loader
