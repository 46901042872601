import React from 'react'
import PropTypes from 'prop-types'
import Button from 'components/Button'
import { COLORS } from 'lib/const'

const TogggleButton = (props) => {
  const { isToggled, onToggle, onToggleOn, onToggleOff, color, ...rest } = props

  return (
    <Button
      {...rest}
      color={isToggled ? color : 'secondary'}
      onClick={(e) => {
        onToggle(e, !isToggled)
        if (isToggled) {
          onToggleOff(e)
        } else {
          onToggleOn(e)
        }
      }}
    />
  )
}

TogggleButton.defaultProps = {
  isToggled: false,
  color: 'success',
  onToggle: () => {},
  onToggleOn: () => {},
  onToggleOff: () => {}
}

TogggleButton.propTypes = {
  size: Button.propTypes.size,
  color: PropTypes.oneOf(COLORS),
  block: Button.propTypes.block,
  isToggled: PropTypes.bool,
  onToggle: PropTypes.func,
  onToggleOn: PropTypes.func,
  onToggleOff: PropTypes.func
}

export default TogggleButton
