import React from 'react'
import { localeData } from 'moment-timezone'
import { range } from 'lodash'
import PropTypes from 'prop-types'
import Button from 'components/Button'
import Icon from 'components/Icon'
import DropdownButtonSimple from 'components/DropdownButtonSimple'

const NavigationYearToDate = ({ date, setDate, children }) => (
  <div className='report-header__navigation'>
    {children}
    <div className='report-header__navigation-item'>
      <Button color='secondary' onClick={() => { setDate(date.clone().subtract(1, 'month')) }}><Icon name='caret-left' /></Button>
    </div>

    <div className='report-header__navigation-item'>
      <DropdownButtonSimple
        color='default'
        style={{ minWidth: 135, textAlign: 'center' }}
        className='btn--flex'
        content={({ close }) => (
          <React.Fragment>
            {localeData().months().map((month, i) => (
              <Button
                key={month}
                block
                disabled={date.month() === i}
                style={{ paddingRight: 35 }}
                onClick={e => {
                  setDate(date.clone().month(i))
                  close()
                }}
              >
                {month}
              </Button>
            ))}
          </React.Fragment>
        )}
      >
        <span>{date.format('MMMM')}</span>
        <Icon name='caret-down' fw style={{ marginLeft: 3.5 }} />
      </DropdownButtonSimple>
    </div>

    <div className='report-header__navigation-item'>
      <DropdownButtonSimple
        color='default'
        style={{ textAlign: 'center' }}
        className='btn--flex'
        content={({ close }) => (
          <React.Fragment>
            {range(date.year() - 5, date.year() + 6).map(year => (
              <Button
                key={year}
                block
                disabled={date.year() === year}
                style={{ paddingRight: 35 }}
                onClick={e => {
                  setDate(date.clone().year(year))
                  close()
                }}
              >
                {year}
              </Button>
            ))}
          </React.Fragment>
        )}
      >
        <span>{date.format('YYYY')}</span>
        <Icon name='caret-down' fw style={{ marginLeft: 3.5 }} />
      </DropdownButtonSimple>
    </div>

    <div className='report-header__navigation-item'>
      <Button color='secondary' onClick={() => { setDate(date.clone().add(1, 'month')) }}><Icon name='caret-right' /></Button>
    </div>
  </div>
)

NavigationYearToDate.propTypes = {
  date: PropTypes.object.isRequired,
  setDate: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
}

export default NavigationYearToDate
