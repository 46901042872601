import { connect } from 'react-redux'
import { selectReports } from 'reports/scrapPareto/selectors'
import ParetoReportGraph from 'components/ParetoReportGraph'
import ScrapParetoReportGraphTooltip from './ScrapParetoReportGraphTooltip'
import ScrapParetoReportGraphLabel from './ScrapParetoReportGraphLabel'

const dataFormatter = duration => `${Math.round(duration)}`

const mapStateToProps = (state) => ({
  data: selectReports(state),
  dataFormatter,
  dataKey: 'quantity',
  GraphLabel: ScrapParetoReportGraphLabel,
  GraphTooltip: ScrapParetoReportGraphTooltip
})

export default connect(mapStateToProps)(ParetoReportGraph)
