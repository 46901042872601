import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Loader from 'components/Loader'
import ScrapParetoReportHeader from 'reports/scrapPareto/ScrapParetoReportHeader'
import ScrapParetoReportLocations from 'reports/scrapPareto/ScrapParetoReportLocations'
import ScrapParetoReportGraph from 'reports/scrapPareto/ScrapParetoReportGraph'

const ScrapParetoReport = ({ isLoaded, plant }) => {
  if (isLoaded) {
    return (
      <React.Fragment>
        <ScrapParetoReportHeader />
        <ScrapParetoReportLocations />
        <ScrapParetoReportGraph />
      </React.Fragment>
    )
  } else {
    return <Loader full />
  }
}

ScrapParetoReport.propTypes = {
  isLoaded: PropTypes.bool.isRequired
}

const mapStateToProps = (state) => ({
  isLoaded: state.init.isFinished
})

export default connect(mapStateToProps)(ScrapParetoReport)
