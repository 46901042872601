import React from 'react'
import PropTypes from 'prop-types'
import Button from 'components/Button'

class DropdownButtonSimple extends React.Component {
  state = { isOpen: false }

  componentDidMount () {
    document.addEventListener('mousedown', this.handleClickOutside)
  }

  componentWillUnmount () {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  setWrapperRef = ref => {
    this.wrapperRef = ref
    return true
  }

  handleClickOutside = e => {
    if (this.wrapperRef && !this.wrapperRef.contains(e.target)) {
      this.closeMenu()
    }
  }

  toggleMenu = e => {
    e && e.preventDefault()
    if (this.state.isOpen) {
      this.closeMenu()
    } else {
      this.openMenu()
    }
  }

  openMenu = e => {
    e && e.preventDefault()
    this.setState({ isOpen: true })
  }

  closeMenu = e => {
    e && e.preventDefault()
    this.setState({ isOpen: false })
  }

  render () {
    const { content, ...rest } = this.props

    return (
      <div ref={this.setWrapperRef} className={this.props.className} style={{ position: 'relative' }}>
        <Button {...rest} onClick={this.toggleMenu} />

        {
          this.state.isOpen &&
          <div
            className='btn-dropdown btn-dropdown--simple'
          >
            { content({ close: this.closeMenu }) }
          </div>
        }
      </div>
    )
  }
}

DropdownButtonSimple.defaultProps = {
  color: 'success'
}

DropdownButtonSimple.propTypes = {
  ...Button.propTypes,
  popperConfig: PropTypes.object,
  content: PropTypes.func.isRequired,
  full: PropTypes.bool
}

export default DropdownButtonSimple
