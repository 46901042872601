import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ToggleButton from 'components/ToggleButton'
import { showLocation, hideLocation } from 'reports/scrapPareto/actions'
import { selectLocation, selectIsLocationVisible } from 'reports/scrapPareto/selectors'

const ScrapParetoReportLocationToggle = ({ id, symbol, isVisible, show, hide }) => (
  <ToggleButton
    isToggled={isVisible}
    onToggleOn={() => show(id)}
    onToggleOff={() => hide(id)}
    color='default'
  >
    {symbol}
  </ToggleButton>
)

ScrapParetoReportLocationToggle.propTypes = {
  symbol: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  isVisible: PropTypes.bool,
  show: PropTypes.func.isRequired,
  hide: PropTypes.func.isRequired
}

const mapStateToProps = (state, { id }) => ({
  ...selectLocation(state, id),
  isVisible: selectIsLocationVisible(state, id)
})

const mapDispatchToProps = ({
  show: showLocation,
  hide: hideLocation
})

export default connect(mapStateToProps, mapDispatchToProps)(ScrapParetoReportLocationToggle)
