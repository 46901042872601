import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import ToggleButton from 'components/ToggleButton'

class Shift extends Component {
  setShift = number => {
    if (this.props.disabled) {
      return
    }

    this.props.setShift(number)
  }

  setFirstShift = e => this.setShift(1)
  setSecondShift = e => this.setShift(2)
  setThirdShift = e => this.setShift(3)
  setAllShifts = e => this.setShift(null)

  render () {
    const { disabled, shift } = this.props

    return (
      <Fragment>
        <ToggleButton
          color='default'
          isToggled={shift === 1}
          onToggleOn={this.setFirstShift}
          onToggleOff={this.setAllShifts}
          disabled={disabled}
        >
          1
        </ToggleButton>
        <ToggleButton
          color='default'
          isToggled={shift === 2}
          onToggleOn={this.setSecondShift}
          onToggleOff={this.setAllShifts}
          disabled={disabled}
        >
          2
        </ToggleButton>
        <ToggleButton
          color='default'
          isToggled={shift === 3}
          onToggleOn={this.setThirdShift}
          onToggleOff={this.setAllShifts}
          disabled={disabled}
        >
          3
        </ToggleButton>
        <ToggleButton
          color='default'
          isToggled={!shift}
          onToggleOn={this.setAllShifts}
          disabled={disabled}
        >
          1 - 3
        </ToggleButton>
      </Fragment>
    )
  }
}

Shift.propTypes = {
  shift: PropTypes.oneOf([1, 2, 3]),
  disabled: PropTypes.bool,
  setShift: PropTypes.func.isRequired
}

export default Shift
