import { createStore, applyMiddleware, compose } from 'redux'
import { createLogger } from 'redux-logger'

const composeEnhancers = (options = {}) => {
  if (process.env.NODE_ENV === 'development') {
    return (
      (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__(options)) ||
      compose
    )
  }
  return compose
}

const createStoreLocal = (
  reducer,
  initialState,
  middleware = [],
  devToolsOptions = {}
) => {
  if (process.env.NODE_ENV === 'development') {
    middleware = [createLogger({ collapsed: true }), ...middleware]
  }

  const store = createStore(
    reducer,
    initialState,
    composeEnhancers(devToolsOptions)(
      applyMiddleware(...middleware)
      // other store enhancers if any
    )
  )

  return store
}

export const createStoreCreator = (reducer, name = 'Extrusion Planning') => {
  const devToolsOptions = { name }
  return (initialState, middleware = []) =>
    createStoreLocal(reducer, initialState, middleware, devToolsOptions)
}
