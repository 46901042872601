import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { COLORS } from 'lib/const'

const PageHeader = ({ title, double, small, color, children, className }) => {
  return (
    <div
      className={classnames(
        'page-header',
        { 'page-header--double': double },
        { 'page-header--small': small },
        color !== 'default' ? 'page-header--' + color : null,
        className
      )}
    >
      {title}
      <div className='page-header__actions'>
        {React.Children.map(children, child =>
          React.cloneElement(child, { className: classnames('page-header__action', child.props.className) })
        )}
      </div>
    </div>
  )
}

PageHeader.defaultProps = {
  title: '',
  double: false,
  small: false,
  color: 'default'
}

PageHeader.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ]),
  double: PropTypes.bool,
  small: PropTypes.bool,
  color: PropTypes.oneOf(COLORS),
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.node
  ]),
  className: PropTypes.string
}

export default PageHeader
