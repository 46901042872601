import React from 'react'
import PropTypes from 'prop-types'

const ProductionReportLocations = ({ ids, Toggle, SelectAll, SelectNone }) => (
  <div className='btn-group btn-group--flex' style={{ padding: 0 }}>
    {ids.map(id => (
      <Toggle key={id} id={id} />
    ))}
    {SelectAll && <SelectAll />}
    {SelectNone && <SelectNone />}
  </div>
)

ProductionReportLocations.defaultProps = {
  ids: []
}

ProductionReportLocations.propTypes = {
  ids: PropTypes.arrayOf(PropTypes.number),
  Toggle: PropTypes.func.isRequired,
  SelectAll: PropTypes.func,
  SelectNone: PropTypes.func
}

export default ProductionReportLocations
