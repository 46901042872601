import { DAY_START_HOUR } from 'lib/const'

export const SHIFT_START_MINUTES = [360, 870, 1380]

export const shift = hour => {
  const result = Math.floor((hour - DAY_START_HOUR) / 8) + 1
  return result > 0 ? result : 3
}

export const isShiftStart = time => {
  const timeMidnight = time.clone().startOf('day')

  const diffMinutes = time.diff(timeMidnight, 'minutes')

  return SHIFT_START_MINUTES.includes(diffMinutes)
}
