import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { selectScrapType } from 'reports/scrapPareto/selectors'

function truncate (string, length) {
  if (string.length > length) {
    return string.substr(0, length) + '...'
  } else {
    return string
  }
}

const ScrapParetoReportGraphLabel = ({ x, y, scrapType }) => {
  if (!scrapType) {
    return null
  }

  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={8} textAnchor='start' fill='#666' transform='rotate(66)'>
        {scrapType.symbol} - {truncate(scrapType.description, 20)}
      </text>
    </g>
  )
}

ScrapParetoReportGraphLabel.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  scrapType: PropTypes.shape({
    symbol: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired
  })
}

const mapStateToProps = (state, { payload: { value: scrapTypeId } }) => ({
  scrapType: selectScrapType(state, scrapTypeId)
})

export default connect(mapStateToProps)(ScrapParetoReportGraphLabel)
