import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { selectScrapType } from 'reports/scrapPareto/selectors'

const ScrapParetoReportGraphTooltip = ({ scrapType, payload, ...rest }) => {
  if (!payload) {
    return null
  }

  const [quantity, total] = payload

  if (!scrapType || !quantity || !total) {
    return null
  }

  return (
    <div className='report-graph-tooltip'>
      <div className='report-graph-tooltip__content'>
        <h4 className='report-graph-tooltip__header'>{scrapType.symbol}</h4>
        <div>{scrapType.description}</div>
      </div>

      <div className='report-graph-tooltip__footer'>
        {Math.round(quantity.value)} / {Math.round(total.value * 1000) / 10.0} %
      </div>
    </div>
  )
}

ScrapParetoReportGraphTooltip.propTypes = {
  label: PropTypes.number,
  payload: PropTypes.array,
  scrapType: PropTypes.shape({
    symbol: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired
  })
}

const mapStateToProps = (state, { label: scrapTypeId }) => ({
  scrapType: selectScrapType(state, scrapTypeId)
})

export default connect(mapStateToProps)(ScrapParetoReportGraphTooltip)
